import Header from "../../features/homePages/common/HomeHeader";
import React, { useEffect, useState } from "react";
import DefaultImage from "../../assets/images/defaultimage.png";
import { useNavigate } from "react-router-dom";
import {
  pagination,

} from "../../components/common/utils/message";
import ReactPaginate from "react-paginate";
import {
  getStaffList,
  userbyId,
} from "../customerInterface/Service/Auth.Service";
import {  API_BASEURL } from "../../environment";
import { useDispatch, useSelector } from "react-redux";

import { MultiSelect } from "primereact/multiselect";
import { searchData } from "../customerInterface/Service/Auth.Service";
import { Card } from "reactstrap";
import { RestroAcceptedRequestList, getAllPubList } from "../staffInterface/service/Auth.service";

const CustomerDashboard = () => {
  const dispatch = useDispatch();
  const [resPayload, setResPayload] = useState();

  const [historyState, setHistoryState] = useState(0);
  // const [limit, setLimit] = useState(pagination.projectLimit);
  const [limit, setLimit] = useState(pagination.scrollLimit);
  const [page, setPage] = useState(pagination.page);
  const [limitPub, setLimitPub] = useState(pagination.page);
  const [pagePub, setPagePub] = useState(pagination.page);
  const [totalPages, settotalPages] = useState();
  const [totalPagesPub, settotalPagesPub] = useState();
  // console.log("totalPageswsw",totalPages);

  // console.log("totalPages",totalPagesPub);

  
  const [username, setUsername] = useState();
  const [data, setData] = useState([]);
  const [model, setModel] = useState(false);
  const [pubData, setPubData] = useState([]);

  const [input, setInput] = useState({
    Key: "",
  });
  const [searchDataItem, setSearchDataItem] = useState([]);

  const [modData, setModData] = useState(false);

  const [selectedCities, setSelectedCities] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const cities = [
    { name: "PubStar", code: "staff" },
    { name: "Pub", code: "pub" },
  ];
  const navigate = useNavigate();

  const userdata = useSelector((state) => state);
  let user_id = userdata?.login?.data?.result?._id;

  useEffect(() => {
    setModData(false);
    const up = async () => {
      const response = await userbyId(user_id);
      setUsername(response?.data?.result?.full_name);
    };
    // up();
  }, []);
  useEffect(() => {
    _getListing();
    _getRestroAcceptedRequestList()
  }, [dispatch]);

  const _getListing = () => {
    getStaffList(
      { page, limit },
      (data) => {
        console.log("data111",data)
        setResPayload(data?.result?.docs);
        settotalPages(data?.result?.totalPages);
      },
      dispatch
    );
  };

  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await getStaffList(
      {
        page: currentPage,
        limit,
      },
      (response) => {
        setResPayload(response?.result?.docs);
      },
      dispatch
    );
  };

  const handlePageChangePub = async (param) => {
    let currentPage = param.selected + 1;
    setPagePub(currentPage);
    await getAllPubList(
      {
        page: currentPage,
        limit,
      },
      (response) => {
        setPubData(response?.docs);
      },
      dispatch
    );
  };
  
  const _getRestroAcceptedRequestList = () => {
    getAllPubList(
      { limit, limitPub,  },
   
      (data) => {
        console.log("data",data)
        setPubData(data?.docs)
        settotalPagesPub(data?.totalPages);
      },
      dispatch
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((previousValue) => ({
      ...previousValue,
      [name]: value,
    }));
  };

  const onTabClick = (tabId) => () => {
    setHistoryState(tabId);
  };

  const buttonHandlerpage = async (data) => {
    navigate(`/customer/staffprofile/${data?._id}`);
  };
 
  const buttonrestaurantHandlerpage = async (data) => {
    navigate(`/customer/restaurantprofilescreen/${data?._id}`);
  };
  //

  // const _allListing = (Key = "") => {
  //   if (Key === "") {
  //     setModel(false);
  //   } else {
  //     let filter;
  //     let param;
  //     if (selectedCities?.length > 1) {
  //       filter = selectedCities.reduce((acc, city) => {
  //         acc[city.code] = city.code;
  //         return acc;
  //       }, {});
  //       param = {
  //         Key: Key,
  //         pub: "pub",
  //         staff: "staff",
  //         page: page,
  //         limit: limit,
  //       };
  //     } else if (selectedCities?.length === 1) {
  //       if (selectedCities[0].code === "staff") {
  //         filter = { staff: "staff" };
  //         param = { Key: Key, staff: "staff", page: page, limit: limit };
  //       } else {
  //         filter = { pub: "pub" };
  //         param = { Key: Key, pub: "pub", page: page, limit: limit };
  //       }
  //     } else {
  //       filter = {};
  //       param = { Key: Key, page: page, limit: limit };
  //     }
  //     setModel(true);
  //     searchData(param, (resdata) => {
  //       setData(resdata?.result);
  //     });
  //   }
  // };


  const _allListing = (Key = "") => {
    setModData(false);
    if (Key === "") {
      setModel(false);
    } else{
      setModel(true);
    }
      let param;
      param = { Key: Key,  pub: "",
      staff: "",  page: page, limit: limit };
if(historyState === 0){
  param.staff = "staff"
}
if(historyState === 1){
  param.pub = "pub"

}
console.log("Asasfaf" , param)

      searchData(param, (resdata) => {
        setData(resdata?.result);
        if( Key === ""){
          setData([]);
          setSearchDataItem([]);
        }
      });
      
    
  };
  // start here

  const buttonviewHandlerpageResto = async (data) => {
    navigate(`/customer/restaurantprofilescreen/${data?._id}`);
  };

  const buttonviewHandlerpage = async (data) => {
    setSearchDataItem([data]);
    setModData(true);
  };

  return (
    <>
      <div className="customerDashboard">
        <Header />
        <section className="main">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="content">
                  <h3 className="blackText Aeonik-Bold">
                    {/* {(userdata?.login?.data?.result?.full_name).toUpperCase()}!{" "} */}
                    {userdata?.login?.data?.result?.full_name}!{" "}
                    <img src="../../assets/hand.png" alt="" />
                  </h3>
                  <h1 className="blackText Spoof-Black">
                    WELCOME TO <span className="blackText">GOTIPME</span>
                  </h1>
                  <p className="blackText Aeonik-Regular">Rewarding great service </p>
                  {/* <p className="blackText Aeonik-Regular">
                    REWARDING GREAT SERVICE{" "}
                  </p> */}

                  <div className="row my-3">
                    <div className="col">
                      <div className="toolsContainer">
                        <div className="row justify-content-center">
                          <div className="col-md-4  mt-md-0">
                            <div className="searchBox">
                              <input
                                type="search"
                                name="Key"
                                onChange={(e) => _allListing(e.target.value)}
                                className="form-control"
                                placeholder={`Search ${
                                  historyState === 0 ? "pubstars" : "pubs"
                                }`}
                              />
                              <i className="fa-solid fa-magnifying-glass"></i>
                              <div
                                className={
                                  model ? "serchResults" : "serchResults d-none"
                                }
                                style={{
                                  maxHeight: "500px",
                                  overflowY: "auto",
                                }}
                              >
                                {/* <div className={  modData   ? "d-none" : "serchResults" }> */}

                                {modData == false ? (
                                  <>
                                    {data?.length ? (
                                      data?.map((item, index) => {
                                        console.log("item", item);
                                        let path = `${API_BASEURL}/public/posts/${item?.image}`;
                                        let ProfilePath = `${API_BASEURL}/public/profile/${item?.profile}`;
                                        // let ProfilePath = `${API_BASEURL}/public/profile/${item?.profile}`;
                                        console.log("path", ProfilePath);
                                        return (
                                          <div className="result" key={index}>
                                            <div className="roundImage">
                                              {/* <img src="./assets/searchresults.png" alt="" /> */}
                                              {/* <img
                                          src={item && item?.image != undefined ? path : "ddd"}
                                          alt=""
                                        /> */}
                                              {historyState === 0 ? (
                                                <img
                                                  src={
                                                    item?.profile != null ||
                                                    item?.profile != undefined
                                                      ? ProfilePath
                                                      : DefaultImage
                                                  }
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  src={
                                                    item?.image != null ||
                                                    item?.image != undefined
                                                      ? path
                                                      : DefaultImage
                                                  }
                                                  alt=""
                                                />
                                              )}
                                              {/* <img
                                                src={
                                                  item?.userDetails?.profile != null ||
                                                  item?.userDetails?.profile != undefined
                                                    ? path
                                                    : DefaultImage
                                                }
                                                alt=""
                                              /> */}
                                            </div>
                                            <div
                                              className="text "
                                              onClick={(e) =>
                                                buttonviewHandlerpage(item)
                                              }
                                            >
                                              <p className="mb-0">
                                                {item.restaurant_name
                                                  ? item.restaurant_name
                                                  : item.full_name}{" "}
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <p
                                        style={{
                                          color: "black",
                                          textAlign: "center",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        No records found
                                      </p>
                                    )}
                                  </>
                                ) : (
                                  " "
                                )}
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-md-2  mt-md-0">
                            <div
                              className="filters"
                              style={{ paddingTop: "10px" }}
                            >
                              <div
                                className="card flex justify-content-center"
                                style={{ border: "1px solid black" }}
                              >
                                <MultiSelect
                                  value={selectedCities}
                                  onChange={(e) => setSelectedCities(e.value)}
                                  options={cities}
                                  optionLabel="name"
                                  placeholder="Filter"
                                  maxSelectedLabels={3}
                                  className="w-full md:w-20rem"
                                />
                                <i class="fa-solid fa-filter blackText"></i>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {searchDataItem.length > 0 ? (
          <section className="hospoStars darkBackground">
            {/* <h1 class="whiteText  Aeonik-Regular"> FROM SEARCH DATA </h1> */}
            <div className="container">
              <div className="row mt-4">
                <div className="col text-center ">
                  <h2 class="whiteText  Aeonik-Bold">Leave some love</h2>
                </div>
              </div>
              <div className="row">
                <div className="col"></div>
              </div>
              <div
                className="row mt-4 paddingAll"
                style={{ background: "#f4f4f4", borderRadius: "20px" }}
              >
                {searchDataItem &&
                  searchDataItem?.map((data, index) => {
                    //  let ProfilePath = `${API_BASEURL}/public/profile/${data?.userDetails?.profile}`;
                    let ProfilePath = `${API_BASEURL}/public/profile/${data?.profile}`;
                    let ProfilePathResto = `${API_BASEURL}/public/posts/${data?.image}`;
                    // let path = `${API_BASEURL}/public/posts/${item?.image}`;
                    return (
                      <div className="col-md-3 cursorPointer" key={index}>
                        <div className="starContent">
                          <div className="profile">
                            {/* <img src="../../assets/startProfile.png" alt="" /> */}

                            {data.role == 2 ? (
                              <img src={ProfilePathResto} alt="" />
                            ) : (
                              <img
                                src={
                                  data?.profile != null ||
                                  data?.profile != undefined
                                    ? ProfilePath
                                    : DefaultImage
                                }
                                alt=""
                                onClick={(e) => {
                                  data.role == 2
                                    ? buttonviewHandlerpageResto(data)
                                    : buttonHandlerpage(data);
                                }}
                              />
                            )}
                          </div>
                          <div className="text">
                            <h4
                              onClick={(e) => {
                                data.role == 2
                                  ? buttonviewHandlerpageResto(data)
                                  : buttonHandlerpage(data);
                              }}
                            >
                              {data.role == 2
                                ? data?.restaurant_name
                                : data?.full_name}
                            </h4>
                            {data.role == 2 ? <p></p> : <p>Staff</p>}
                            {/* <p>Bartendar</p> */}
                            {/* <h5>{data?.restaurant_name}</h5> */}
                            <p>
                              <i className="fa-solid fa-location-dot"></i>{" "}
                              {data?.addressLine1} <span>{data?.city}</span>
                            </p>
                          </div>
                          <div
                            className="nextBtn"
                            onClick={(e) => {
                              data.role == 2
                                ? buttonviewHandlerpageResto(data)
                                : buttonHandlerpage(data);
                            }}
                          >
                            <i className="fa-solid fa-arrow-right"></i>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="row mt-5">
                <div className="col text-center">
                  {/* <button className="btn btn-main-outline">View More</button> */}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="hospoStars newStars">
            <div className="container" style={{ paddingBottom: "20px" }}>
              <div className="row">
                <div className="col text-center paddingBottom">
                  <h2 style={{ color: "#f4f4f4" }}>Leave some love</h2>
                </div>
              </div>
              <Card className="paddingAll paddingBottom0">
                <div className="row">
                  <div
                    className={`col-6 borderRight whiteBackground alc ${
                      historyState !== 0 && "Whitebackground"
                    }`}
                  >
                    <h1
                      className={`Aeonik-Bold cursorPointer  ${
                        historyState !== 0 && "smallText"
                      }`}
                      onClick={onTabClick(0)}
                    >
                      Pubstars
                    </h1>

                    <p className="Aeonik-Regular">
                      Find your servos, leave some love
                    </p>
                  </div>
                  <div
                    className={`col-6 whiteBackground alc ${
                      historyState !== 1 && "Whitebackground"
                    }`}
                  >
                    <h1
                      className={`Aeonik-Bold cursorPointer ${
                        historyState !== 1 && "smallText"
                      }`}
                      onClick={onTabClick(1)}
                    >
                      Pubs
                    </h1>
                    <p className="Aeonik-Regular">
                      Find your servos, leave some love
                    </p>
                  </div>
                </div>
                <hr />
                <div
                  className={`tabContent row mt-4 ${
                    historyState !== 0 && "d-none"
                  }`}
                >
                  {resPayload &&
                    resPayload?.map((data, index) => {
                      let ProfilePath = `${API_BASEURL}/public/profile/${data?.userDetails?.profile}`;
                      return (
                        <div className="col-md-3 cursorPointer" key={index}>
                          <div className="starContent">
                            <div
                              className="profile"
                              onClick={(e) =>
                                buttonHandlerpage(data?.userDetails)
                              }
                            >
                              {/* <img src="../../assets/startProfile.png" alt="" /> */}
                              <img
                                src={
                                  data?.userDetails?.profile != null ||
                                  data?.userDetails?.profile != undefined
                                    ? ProfilePath
                                    : DefaultImage
                                }
                                alt=""
                              />
                            </div>
                            <div className="text">
                              <h4
                                className="Aeonik-Bold"
                                onClick={(e) =>
                                  buttonHandlerpage(data?.userDetails)
                                }
                              >
                                {data?.userDetails?.full_name}
                              </h4>
                              <p className="blackText Aeonik-Regular">
                                PubStar
                              </p>
                              <h5
                                className="Aeonik-Regular"
                                onClick={(e) =>
                                  buttonrestaurantHandlerpage(
                                    data?.restoDetails
                                  )
                                }
                              >
                                {data?.restoDetails?.restaurant_name}
                              </h5>
                              <p className="blackText Aeonik-Regular">
                                {data?.restoDetails?.addressLine1 ? (
                                  <i className="fa-solid fa-location-dot"></i>
                                ) : (
                                  ""
                                )}
                                {data?.restoDetails?.addressLine1}{" "}
                                <span>{data?.restoDetails?.city}</span>
                              </p>
                            </div>
                            <div
                              className="nextBtn"
                              onClick={(e) =>
                                buttonHandlerpage(data?.userDetails)
                              }
                            >
                              <i className="fa-solid fa-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {resPayload?.length == 0 ? "No Records found" : ""}
                  </div>
                </div>
                <div
                  className={`tabContent row mt-4 ${
                    historyState !== 1 && "d-none"
                  }`}
                >
                  {pubData &&
                    pubData?.map((data, index) => {
                      let ProfilePath = `${API_BASEURL}/public/posts/${data?.image}`;
                      return (
                        <div className="col-md-3 cursorPointer" key={index}>
                          <div className="starContent">
                            <div
                              className="profile"
                              onClick={(e) => buttonrestaurantHandlerpage(data)}
                            >
                              {/* <img src="../../assets/startProfile.png" alt="" /> */}
                              <img
                                src={
                                  data?.image != null ||
                                  data?.image != undefined
                                    ? ProfilePath
                                    : DefaultImage
                                }
                                alt=""
                              />
                            </div>
                            <div className="text">
                              <h4
                                className="Aeonik-Bold"
                                onClick={(e) =>
                                  buttonrestaurantHandlerpage(data)
                                }
                              >
                                {data?.full_name}
                              </h4>
                              <p className="blackText Aeonik-Regular">Pub</p>
                              <h5
                                className="Aeonik-Regular"
                                onClick={(e) =>
                                  buttonrestaurantHandlerpage(data)
                                }
                              >
                                {data?.restaurant_name}
                              </h5>
                              <p className="blackText Aeonik-Regular">
                                {data?.addressLine1 ? (
                                  <i className="fa-solid fa-location-dot"></i>
                                ) : (
                                  ""
                                )}
                                {data?.addressLine1} <span>{data?.city}</span>
                              </p>
                            </div>
                            <div
                              className="nextBtn"
                              onClick={(e) => buttonrestaurantHandlerpage(data)}
                            >
                              <i className="fa-solid fa-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {resPayload?.length == 0 ? "No Records found" : ""}
                  </div>
                </div>

                <div className={`row my-5 ${historyState !== 0 && "d-none"}`}>
                  <div className="col">
                    <nav
                      aria-label="Page navigation example"
                      class="paginationRow"
                    >
                      {totalPages > 1 ? (
                        <ReactPaginate
                          breakLabel={"..."}
                          nextLabel={"Next >"}
                          onPageChange={handlePageChange}
                          pageCount={totalPages}
                          previousLabel={"< Back"}
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link prevButton"}
                          nextClassName={"page-item nextPage"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </nav>
                  </div>
                </div>
                <div className={`row my-5 ${historyState !== 1 && "d-none"}`}>
                  <div className="col">
                    <nav
                      aria-label="Page navigation example"
                      class="paginationRow"
                    >
                      {totalPagesPub > 1 ? (
                        <ReactPaginate
                          breakLabel={"..."}
                          nextLabel={"Next >"}
                          onPageChange={handlePageChangePub}
                          pageCount={totalPagesPub}
                          previousLabel={"< Back"}
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </nav>
                  </div>
                </div>
              </Card>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default CustomerDashboard;
