import { GetLandingPageData } from "../Service/AuthService";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import footerLogo from "../../../assets/images/footerLogo.png";
import hompageLogo from "../../../assets/images/mobileFooter.png"

function HomeFooter() {
  const [landingpagedata, setLandingPageData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    _getLandingPageData();
  }, [dispatch]);

  const _getLandingPageData = () => {
    GetLandingPageData(
      {},
      (data) => {
        setLandingPageData(data[0]);
      },
      dispatch
    );
  };
  const reduxData = useSelector((state) => state);
  let loggedIn = reduxData?.login?.isLoggedIn;

  let reduxUserRole = reduxData?.login?.data?.result?.role;
  const handleLogoClick = () => {
    if (reduxUserRole == 1) {
      navigate("/customer/dashboard");
    } else if (reduxUserRole == 2) {
      navigate("/resto/mainrestodashboard");
    } else if (reduxUserRole == 3) {
      navigate("/staff/dashboard");
    }
  };
  return (
    <section className="footerSection footerTheme">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 jcs dflex mobilepaddingBottom">
            <img
              src={footerLogo}
              alt=""
              className="webView"
              height="120px"
              onClick={() => {
                navigate("/");
              }}
            />
            <img
              src={hompageLogo}
              alt=""
              className="mobileView"
              style={{ width: "111.92px", marginLeft: "15px" }}
              height="fit-content"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
          <div className="col-md-5"></div>
          <div className="col-md-5 alc">
            <div className="col-12 mainButtondiv dflex alc jce">
              {/* <Link className="noDecoration" to={"/privacy_policy"}> */}
              {/* <a className="nav-link">
                <Link to="/aboutus" className="custom-link">
                  About
                </Link>
              </a> */}

              <button
                className="btn btn-height footerButton btn-main mobileFooterButton dflex alc jcc"
                onClick={() => {
                  navigate("/aboutus");
                }}
              >
                About
              </button>
              <button
                className="btn btn-height footerButton btn-main mobileFooterButton dflex alc jcc"
                onClick={() => {
                  navigate("/privacy_policy");
                }}
              >
                Privacy Policy
              </button>
              <button
                className="btn btn-height footerButton btn-main mobileFooterButton dflex alc jcc"
                onClick={() => {
                  navigate("/terms_conditions");
                }}
              >
                Terms & Conditions
              </button>
              {/* </Link> */}
              <button
                className="btn btn-height footerButton btn-main mobileFooterButton dflex alc jcc"
                onClick={() => {
                  navigate("/cookiePolicy");
                }}
              >
                Cookie Policy
              </button>
              <button
                className="btn btn-height footerButton btn-main mobileFooterButton dflex alc jcc"
                onClick={() => {
                  navigate("/rights");
                }}
              >
                All Rights Reserved
              </button>
            </div>
            <div className="col-12 designText">
              <p>Design by Shoreditch Design</p>
            </div>
            <div className="col-12 footerLogo dflex jce">
              <div className="icon">
                <i className="fa-brands fa-facebook-f  whiteText"></i>
              </div>
              <div className="icon">
                <i className="fa-brands fa-instagram whiteText"></i>
              </div>
              <div className="icon">
                <i className="fa-brands fa-linkedin whiteText"></i>
              </div>
              <div className="icon">
                <i className="fa-brands fa-twitter whiteText"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeFooter;
