import Header from "../homePages/common/HomeHeader";
import React, { useEffect, useState } from "react";
import DefaultImage from "../../assets/images/defaultimage.png";
import { Link, useNavigate } from "react-router-dom";
import {
  pagination,

} from "../../components/common/utils/message";
import ReactPaginate from "react-paginate";
import {
  getStaffList,
  userbyId,
} from "../customerInterface/Service/Auth.Service";
import {  API_BASEURL } from "../../environment";
import { useDispatch, useSelector } from "react-redux";

import { MultiSelect } from "primereact/multiselect";
import { searchData } from "../customerInterface/Service/Auth.Service";
import { Card } from "reactstrap";
import gif from "../../assets/images/HomeV.gif";
import hompageLogo from "../../assets/images/mainPageLogo.png"
import mobileNavLogo from "../../assets/images/mobileNavLogo.png"

const SearchPubstar = () => {
  const dispatch = useDispatch();
  const [resPayload, setResPayload] = useState();
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [totalPages, settotalPages] = useState();
  const [username, setUsername] = useState();
  const [data, setData] = useState([]);
  const [model, setModel] = useState(false);
  const [input, setInput] = useState({
    Key: "",
  });

  const [searchDataItem, setSearchDataItem] = useState([]);
console.log("searchDataItem",searchDataItem);
  const [modData, setModData] = useState(false);
  const [searchType,setSearchType] = useState("")

  const [selectedCities, setSelectedCities] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const cities = [
    { name: "PubStar", code: "staff" },
    { name: "Pub", code: "pub" },
  ];
  const navigate = useNavigate();

  const userdata = useSelector((state) => state);
  let user_id = userdata?.login?.data?.result?._id;

  useEffect(() => {
    setModData(false);
    const up = async () => {
      const response = await userbyId(user_id);
      setUsername(response?.data?.result?.full_name);
    };
    up();
  }, []);
  useEffect(() => {
    _getListing();
    _allListing()
  }, [dispatch]);

  const _getListing = () => {
    getStaffList(
      { page, limit },
      (data) => {
      console.log("dtaaaa",data)
        setResPayload(data?.result?.docs);
        // settotalPages(data?.result?.totalPages);
      },
      dispatch
    );
  };

  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await getStaffList(
      {
        page: currentPage,
        limit,
      },
      (response) => {
        setResPayload(response?.result?.docs);
      },
      dispatch
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((previousValue) => ({
      ...previousValue,
      [name]: value,
    }));
  };

  const buttonHandlerpage = async (data) => {
    navigate(`/customer/staffprofile/${data?._id}`);
  };
  const buttonrestaurantHandlerpage = async (data) => {
    navigate(`/customer/restaurantprofilescreen/${data?._id}`);
  };
  //

  const _allListing = (Key = "") => {
    setModData(false);
    if (Key === "") {
      setModel(false);
    } else{
      setModel(true);
    }
      let param;
      param = { Key: Key,  pub: "",
      staff: "",  page: page, limit: limit };
if(sessionStorage.getItem('SEARCH') === "pub"){
  param.pub = "pub"
  setSearchType("pub")
}
if(sessionStorage.getItem('SEARCH') === "staff"){
  param.staff = "staff"
  setSearchType("pubstar");

}
      searchData(param, (resdata) => {
        setData(resdata?.result);
        if( Key === ""){
          setSearchDataItem(resdata?.result);
        }
      });
      
    
  };
  // start here

  const buttonviewHandlerpageResto = async (data) => {
    navigate(`/customer/restaurantprofilescreen/${data?._id}`);
  };

  const buttonviewHandlerpage = async (data) => {
    setSearchDataItem([data]);
    setModData(true);
  };

  return (
    <>
      <div className="homePage">
        <div className="container-fluid">
          <div className="customerDashboard">
            <div className="row headerSection">
              <div className="col">
                <div className="header">
                  <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                      <a className="navbar-brand">
                        <Link to="/" className="custom-link">
                          {" "}
                          <img
                            src={hompageLogo}
                            style={{ borderRadius: "20px" }}
                            height={50}
                            width={100}
                            alt=""
                            className="webView"
                          />
                          <img
                            src={mobileNavLogo}
                            className="mobileView"
                            style={{ borderRadius: "20px" }}
                            height={50}
                            width={100}
                            alt=""
                          />
                        </Link>
                      </a>

                      <button
                        className="navbar-toggler btn-main"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        style={{ background: "#faf14b", color: "black" }}
                        aria-label="Toggle navigation"
                      >
                        {/* <span className="navbar-toggler-icon text-light"></span> */}
                        <i className="fa-solid fa-bars navbar-toggler-icon blackText centerMid"></i>
                      </button>
                      <div
                        className="collapse navbar-collapse justify-content-end"
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav mb-2 mb-lg-0">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              aria-current="page"
                              href="/"
                            >
                              Contact
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/">
                              How It Works
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/">
                              Sign Up
                            </a>
                          </li>
                          {/* <li className="nav-item">
                          <a className="nav-link" href="/">
                            Contact Us
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link highlighted" href="/Register">
                            Register as a
                          </a>
                        </li> */}

                          <li>
                            <Link
                              to="/login"
                              className="btn btn-main btn-height"
                            >
                              Login
                            </Link>
                          </li>
                        </ul>
                        <form className="d-flex"></form>
                      </div>
                    </div>
                  </nav>
                </div>
                <div
                  className="headerContent mt-3 tippingImage dflex jcc"
                  id="aboutUs"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <section className="main">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="content">
                  <div className="row my-3">
                    <div className="col">
                      <div className="toolsContainer">
                        <div className="row">
                          <div className="col-md-6 mt-md-0">
                            <div className="searchBox">
                              <input
                                type="search"
                                name="Key"
                                onChange={(e) => _allListing(e.target.value)}
                                className="form-control"
                                // placeholder={`search  ${sessionStorage.getItem('SEARCH') === "staff" ? "pubstars":"pubs" }`}
                                // placeholder={`Find the person/pub that made your night`}
                                placeholder={`Find the ${searchType} that made your night`}
                              />
                              <i className="fa-solid fa-magnifying-glass"></i>
                              <div
                                className={
                                  model ? "serchResults" : "serchResults d-none"
                                }
                                style={{
                                  maxHeight: "500px",
                                  overflowY: "auto",
                                }}
                              >
                                {modData == false ? (
                                  <>
                                    {data?.length ? (
                                      data?.map((item, index) => {
                                        let ProfilePath = `${API_BASEURL}/public/profile/${item?.profile}`;
                                        let ProfilePathResto = `${API_BASEURL}/public/posts/${item?.image}`;
                                        return (
                                          <div className="result" key={index}>
                                            <div className="roundImage">
                                              {item?.role == 2 ? (
                                                <img
                                                  src={ProfilePathResto}
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  src={
                                                    item?.profile != null ||
                                                    item?.profile != undefined
                                                      ? ProfilePath
                                                      : DefaultImage
                                                  }
                                                  alt=""
                                                  //  onClick={(e) => {
                                                  //   data.role == 2
                                                  //     ? buttonviewHandlerpageResto(data)
                                                  //     : buttonHandlerpage(data);
                                                  // }}
                                                />
                                              )}

                                              {/* <img
                                                src={
                                                  item?.image != null ||
                                                  item?.image != undefined
                                                    ? ProfilePath
                                                    : DefaultImage
                                                }
                                                alt=""
                                              /> */}
                                            </div>
                                            <div
                                              className="text "
                                              onClick={(e) =>
                                                buttonviewHandlerpage(item)
                                              }
                                            >
                                              <p className="mb-0">
                                                {item.restaurant_name
                                                  ? item.restaurant_name
                                                  : item.full_name}{" "}
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <p
                                        style={{
                                          color: "black",
                                          textAlign: "center",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        No records found
                                      </p>
                                    )}
                                  </>
                                ) : (
                                  " "
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {searchDataItem.length > 0 ? (
          <section className="hospoStars darkBackground">
            <div className="container">
              <div className="row">
                <div className="col text-center"></div>
              </div>
              <div className="row">
                <div className="col"></div>
              </div>
              <div
                className="row mt-4 paddingAll"
                style={{ background: "#f4f4f4", borderRadius: "20px" }}
              >
                {searchDataItem &&
                  searchDataItem?.map((data, index) => {
                    //  let ProfilePath = `${API_BASEURL}/public/profile/${data?.userDetails?.profile}`;
                    let ProfilePath = `${API_BASEURL}/public/profile/${data?.profile}`;
                    let ProfilePathResto = `${API_BASEURL}/public/posts/${data?.image}`;
                    // let path = `${API_BASEURL}/public/posts/${item?.image}`;
                    return (
                      <div className="w-20 cursorPointer" key={index}>
                        <div className="starContent">
                          <div className="profile">
                            {/* <img src="../../assets/startProfile.png" alt="" /> */}

                            {data.role == 2 ? (
                              <img src={ProfilePathResto} alt="" />
                            ) : (
                              <img
                                src={
                                  data?.profile != null ||
                                  data?.profile != undefined
                                    ? ProfilePath
                                    : DefaultImage
                                }
                                alt=""
                                onClick={(e) => {
                                  data.role == 2
                                    ? buttonviewHandlerpageResto(data)
                                    : buttonHandlerpage(data);
                                }}
                              />
                            )}
                          </div>
                          <div className="text">
                            <h4
                              onClick={(e) => {
                                data.role == 2
                                  ? buttonviewHandlerpageResto(data)
                                  : buttonHandlerpage(data);
                              }}
                            >
                              {data.role == 2
                                ? data?.restaurant_name
                                : data?.full_name}
                            </h4>
                            {data.role == 2 ? <p></p> : <p>Pubstar</p>}
                            {/* <p>Bartendar</p> */}
                            {/* <h5>{data?.restaurant_name}</h5> */}
                            {data.role == 2 ? (
                              <p>
                                <i className="fa-solid fa-location-dot"></i>{" "}
                                {data?.addressLine1} <span>{data?.city}</span>
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="nextBtn"
                            onClick={(e) => {
                              data.role == 2
                                ? buttonviewHandlerpageResto(data)
                                : buttonHandlerpage(data);
                            }}
                          >
                            <i className="fa-solid fa-arrow-right"></i>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="row mt-5">
                <div className="col text-center">
                  {/* <button className="btn btn-main-outline">View More</button> */}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="hospoStars newStars">
            <div className="container" style={{ paddingBottom: "20px" }}>
              <div className="row">
                <div className="col text-center paddingBottom"></div>
              </div>
              <Card className="paddingAll paddingBottom0">
                <div className="row">
                  <div className="col">
                    <h1 className="Aeonik-Bold">Hospo Stars</h1>
                    <p className="Aeonik-Regular">
                      Find your servos, leave some love
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row mt-4">
                  {resPayload &&
                    resPayload?.map((data, index) => {
                      let ProfilePath = `${API_BASEURL}/public/profile/${data?.userDetails?.profile}`;
                      return (
                        <div className="col-md-3" key={index}>
                          <div className="starContent">
                            <div
                              className="profile"
                              onClick={(e) =>
                                buttonHandlerpage(data?.userDetails)
                              }
                            >
                              {/* <img src="../../assets/startProfile.png" alt="" /> */}
                              <img
                                src={
                                  data?.userDetails?.profile != null ||
                                  data?.userDetails?.profile != undefined
                                    ? ProfilePath
                                    : DefaultImage
                                }
                                alt=""
                              />
                            </div>
                            <div className="text">
                              <h4
                                className="Aeonik-Bold"
                                onClick={(e) =>
                                  buttonHandlerpage(data?.userDetails)
                                }
                              >
                                {data?.userDetails?.full_name}
                              </h4>
                              <p className="blackText Aeonik-Regular">
                                PubStar
                              </p>
                              <h5
                                className="Aeonik-Regular"
                                onClick={(e) =>
                                  buttonrestaurantHandlerpage(
                                    data?.restoDetails
                                  )
                                }
                              >
                                {data?.restoDetails?.restaurant_name}
                              </h5>
                              <p className="blackText Aeonik-Regular">
                                <i className="fa-solid fa-location-dot"></i>{" "}
                                {data?.restoDetails?.addressLine1}{" "}
                                <span>{data?.restoDetails?.city}</span>
                              </p>
                            </div>
                            <div
                              className="nextBtn"
                              onClick={(e) =>
                                buttonHandlerpage(data?.userDetails)
                              }
                            >
                              <i className="fa-solid fa-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {resPayload?.length == 0 ? "No Records found" : ""}
                  </div>
                </div>

                <div className="row my-5">
                  <div className="col">
                    <nav
                      aria-label="Page navigation example"
                      class="paginationRow"
                    >
                      {totalPages > 1 ? (
                        <ReactPaginate
                          breakLabel={"..."}
                          nextLabel={"Next >"}
                          onPageChange={handlePageChange}
                          pageCount={totalPages}
                          previousLabel={"< Back"}
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </nav>
                  </div>
                </div>
              </Card>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default SearchPubstar;
