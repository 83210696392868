import HomeHeader from "../../homePages/common/HomeHeader";
import {
  addContactPage,
  ContactSupportlistbyid,
} from "../../staffInterface/service/Auth.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import {
  pagination,
} from "../../../components/common/utils/message";
import ReactPaginate from "react-paginate";
import gotip from "../../../assets/images/gotipLogo.png";


function ContactUs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [supportlist, setSupportList] = useState();
  const [totalPages, settotalPages] = useState();
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const userdata = useSelector((state) => state);
  let sent_id = userdata?.login?.data?.result?._id;
  const [error, setErrors] = useState({});
  const [input, setInput] = useState({
    full_name: "",
    email: "",
    message: "",
    is_customer_contact_us: 1,
    sent_by_id: userdata?.login?.data?.result?._id,
    role: userdata?.login?.data?.result?.role,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };
  /*--------@ HandleSubmit for add contact us----------- */

  const handelSubmit = async (e) => {
    handeblur();
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
      let reqData = {
        full_name: input.full_name,
        email: input.email,
        message: input.message,
        is_customer_contact_us: input.is_customer_contact_us,
        sent_by_id: input.sent_by_id,
        role: input.role,
      };
      try {
        addContactPage(
          reqData,
          (result) => {
            if (result?.data?.status == true) {
              toast.success(
                "Submit successfully",
                {
                  theme: "dark",
                  position: toast.POSITION.BOTTOM_RIGHT,
                          autoClose: 1000 
                },
              );
              navigate("/customer/dashboard");
            } else if (result?.data?.status === false) {
              toast.error(
                "Not submit",
                {
                  theme: "dark",
                
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );
            }
          },
          dispatch
        );
      } catch (e) {}
    }
  };
  /*--------@ Validation For contact us----------- */

  const validateForm = () => {
    const errors = {};
    if (!input.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(input.email)) {
      errors.email = "Email is invalid";
    }

    if (!input.full_name) {
      errors.full_name = "Full name is required";
    }

    if (!input.message) {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handeblur = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };

  useEffect(() => {
    _getContactSupportList();
  }, [dispatch]);

  const _getContactSupportList = () => {
    ContactSupportlistbyid(
      { sent_by_id: sent_id, page, limit },
      (data) => {
        setSupportList(data?.docs);
        settotalPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await ContactSupportlistbyid(
      { sent_by_id: sent_id, page: currentPage, limit },
      (response) => {
        setSupportList(response?.docs);
      },
      dispatch
    );
  };
  return (
    <div className="homePage">
      <HomeHeader />
      <div className="container-fluid">
        <div className="row headerSection">
          <div className="col"></div>
        </div>

        <div className="row contactSection" id="contactUs">
          <div
            className="col-md-6 dflex alc"
            style={{ paddingLeft: "30px" }}
            onClick={() => {
              navigate("/customer/dashboard");
            }}
          >
            <img
              src={gotip}
              alt=""
              height="fit-content"
              className="contactUs d-none d-md-block"
              style={{ borderRadius: "20px" }}
            />
            {/* <img
              src={gotip}
              alt=""
              className="mobileImage d-block d-md-none mb-3"
            /> */}
          </div>
          <div className="col-md-6">
            <h1 className="Spoof-Black">Contact Support</h1>
            <div className="contactForm">
              <div className="form-group">
                <label htmlFor="fullName ">Your Name</label>
                <input
                  type="text"
                  className="form-control blackBorder"
                  id="fullName"
                  name="full_name"
                  onChange={handleChange}
                  onBlur={(e) => handeblur()}
                />
              </div>
              {error.full_name && (
                <span className="text-danger">{error.full_name}</span>
              )}
              <div className="form-group">
                <label htmlFor="emailId">Your Email</label>
                <input
                  type="email"
                  className="form-control blackBorder"
                  id="emailId"
                  name="email"
                  onChange={handleChange}
                  required
                  onBlur={(e) => handeblur()}
                />
              </div>
              {error.email && (
                <span className="text-danger">{error.email}</span>
              )}
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="5"
                  className="form-control blackBorder"
                  onChange={handleChange}
                  onBlur={(e) => handeblur()}
                ></textarea>
              </div>
              {error.message && (
                <span className="text-danger">{error.message}</span>
              )}
              {/* <div className="form-group mt-3 ">
                <button className="btn btn-main btn-height" onClick={handelSubmit}>
                  Send
                </button>
              </div> */}
              <div className="form-group mt-3 text-end text-md-start">
                <button
                  className="btn btn-main btn-height"
                  onClick={handelSubmit}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
          <img
            src="./assets/floatElement.png"
            alt=""
            className="floatElement"
          />
        </div>

        <div className="table-wraper">
          <div className="top-action"></div>
          <Table responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th>Sent by</th>
                <th>Message</th>
                <th>Reply by admin</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {supportlist &&
                supportlist?.map((data, index) => {
                  const date = new Date(data?.createdAt);

                  // Extract the day, month, and year values
                  const day = date.getDate().toString().padStart(2, "0");
                  const month = (date.getMonth() + 1)
                    .toString()
                    .padStart(2, "0");
                  const year = date.getFullYear();

                  // Format the date as dd/mm/yyyy
                  const formattedDate = `${day}/${month}/${year}`;
                  return (
                    <tr key={index}>
                      <td>{formattedDate}</td>
                      <td>
                        <p>{data?.sentDetails?.full_name}</p>
                        <p>{data?.sentDetails?.email}</p>
                      </td>
                      <td>{data?.message}</td>

                      <td>
                        {data?.reply_by_admin == undefined || null
                          ? "-"
                          : data?.reply_by_admin}
                      </td>

                      <td>
                        {data?.status == "0" ? (
                          <span className="status-hold">Open</span>
                        ) : (
                          <span className="status-Verified">
                            Resolved{" "}
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21 9.98454L18.6709 7.33091L18.9955 3.81818L15.5495 3.03545L13.7455 0L10.5 1.39364L7.25455 0L5.45045 3.03545L2.00455 3.80864L2.32909 7.32136L0 9.98454L2.32909 12.6382L2.00455 16.1604L5.45045 16.9432L7.25455 19.9786L10.5 18.5754L13.7455 19.9691L15.5495 16.9336L18.9955 16.1509L18.6709 12.6382L21 9.98454ZM8.59091 14.7573L4.77273 10.9391L6.11864 9.59318L8.59091 12.0559L14.8814 5.76545L16.2273 7.12091L8.59091 14.7573Z"
                                fill="#0066FF"
                              />
                            </svg>
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            {supportlist?.length == 0 ? "No Records found" : ""}
          </div>
          <div className="text-end d-flex">
            <Pagination className="ml-auto">
              {totalPages > 1 ? (
                <ReactPaginate
                  breakLabel={"..."}
                  nextLabel={"Next >"}
                  onPageChange={handlePageChange}
                  pageCount={totalPages}
                  previousLabel={"< Back"}
                  renderOnZeroPageCount={null}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              ) : null}
            </Pagination>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
