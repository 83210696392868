
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Card } from "reactstrap";
import hompageLogo from "../../../assets/images/mainPageLogo.png"
import mobileNavLogo from "../../../assets/images/mobileNavLogo.png"

export default function Cookie() {
  return (
    <>
      <div className="homePage">
        <div className="container-fluid">
          <div className="customerDashboard">
            <div className="row headerSection">
              <div className="col">
                <div className="header">
                  <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">



                      <a className="navbar-brand">
                        <Link to="/" className="custom-link">
                          {" "}
                          <img
                            src={hompageLogo}
                            style={{ borderRadius: "20px" }} height={50} width={100}
                            alt=""
                            className="webView"
                          />
                          <img src={mobileNavLogo} className="mobileView" style={{ borderRadius: '20px' }} height={50} width={100} alt="" />
                        </Link>
                      </a>


                      <button
                        className="navbar-toggler btn-main "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        style={{ background: "#faf14b", color: "black" }}
                        aria-label="Toggle navigation">
                        {/* <span className="navbar-toggler-icon text-light"></span> */}
                        <i className="fa-solid fa-bars blackText navbar-toggler-icon centerMid"></i>
                      </button>
                      <div
                        className="collapse navbar-collapse justify-content-end"
                        id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                          {/* <li className="nav-item">
                            <Link to="/aboutus" className="nav-link">About</Link>
                          </li> */}
                          <li className="nav-item">
                            <Link to="/contactUs" className="nav-link">Contact</Link>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/">
                              How It Works
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/">
                              Sign Up
                            </a>
                          </li>
                          {/* <li className="nav-item">
                          <a className="nav-link" href="/">
                            Contact Us
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link highlighted" href="/Register">
                            Register as a
                          </a>
                        </li> */}

                          <li>
                            <Link to="/login" className="btn btn-main btn-height">
                              Login
                            </Link>
                          </li>
                        </ul>
                        <form className="d-flex"></form>
                      </div>
                    </div>
                  </nav>
                </div>
                <div
                  className="headerContent mt-3 tippingImage dflex jcc"
                  id="aboutUs">

                </div>
              </div>
            </div>
          </div>
        </div>



        <section className="hospoStars newStars">
          <div className="container" style={{ paddingBottom: '20px' }}>
            <div className="row">
              <div className="col text-center paddingBottom">

              </div>
            </div>
            <Card className="paddingAll paddingBottom0">
              <div className="row">
                <div className="col">
                  <h1 className="Aeonik-Bold">Cookie Policy</h1>

                </div>
              </div>
              <hr />

            </Card>
          </div>
        </section>

      </div>
    </>
  );
};

