import Header from "../../features/homePages/common/HomeHeader";
import React, { useEffect, useRef, useState } from "react";
import { getbyUserid, addrestroForm, addUserPost, deleteGallery } from "../../services/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { API_BASEURL } from "../../environment";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import LocationMap from "./Service/AutoComplet";
// import { Autocomplete, LoadScript } from "@react-google-maps/api";

// const libraries = ["places"];

const RestoDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState(false)
  const [historyState, setHistoryState] = useState(0);
  const [userData, setUserData] = useState(0);
  const [userData1, setUserData1] = useState();
  const [deletePhoto, setDeletePhoot] = useState(false)

  const [deletePostID, setDeletePostID] = useState("")


  const onTabClick = (tabId) => () => {
    setHistoryState(tabId);
  };
  const userdata = useSelector((state) => state);

  const [geoAddressData, setGeoAddressData] = useState({
    addressLine1: "",
  });
  const inputRef = useRef();
  
  const [geoLocation, setGeoLocation] = useState({
    lat: userData?.lat,
    lng: userData?.lng,
  });

  /*-----------@Handler function to set google address--------------*/
  const handlePlaceChanged = (inputRef) => {
    console.log("places",input);
    const [place] = inputRef?.current?.getPlaces();

    if (place) {
      if (place) {
        const addressLine1 = place?.formatted_address;
        setInput((previousValue) => ({
          ...previousValue,
          addressLine1,
        }));
        setGeoAddressData((previousValue) => ({
          ...previousValue,
          addressLine1,
        }));
      }
      setGeoLocation({
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
      });
    }
  };

  /*-----------@Setting role based on login--------------*/
  let loginRole = userdata?.login?.data?.role;
  let user_id;
  if (loginRole == 2) {
    user_id = userdata?.login?.data?.result?._id;
  }
  if (loginRole == 0) {
    user_id = userdata?.adminrestro?.data?._id;
  }

  const [input, setInput] = useState({
    _id: user_id,
    full_name: userData?.full_name,
    email: userData?.email,
    contact_number: userData?.contact_number,
    restaurant_email: userData?.restaurant_email,
    restaurantContact_number: userData?.restaurantContact_number,
    image: null,
    multipleImages: null,
    addressLine1: userData?.addressLine1,
    addressLine2: userData?.addressLine2,
    countryCode: userData?.countryCode,
    city: userData?.city,
    postcode: userData?.postcode,
    requestCode: userData?.requestCode,
    is_updated: 1,
  });
  useEffect(() => {
    _getUserDetails();
  }, []);


  //MultipleImage uploader 
  var resImage = [];

  const [rest, setRest] = useState([]);
  const [uploadMultipleImage, SetUploadMultipleImage] = useState([]);

  const pubImgaeupload = (e) => {
    let file = e.target.files
    for (let filedata of file) {
      let fileType = filedata?.type.split("/")
      if (fileType[1] !== "jpeg" && fileType[1] !== "jpg" && fileType[1] !== "png") {
        return toast.error(
          "Please Upload The image with extension jpg , jpeg and png!",
          {
            theme: "dark",
          },
          {
            position: toast.POSITION.TOP_CENTER,
          },
          { autoClose: 1000 }
        );

      }
    }


    for (let i = 0; i <= e.target.files.length - 1; i++) {
      SetUploadMultipleImage(prevState => [...prevState, file[i]])
    }
  };
  const removeImage = (index) => {

    SetUploadMultipleImage([
      ...uploadMultipleImage.slice(0, index),
      ...uploadMultipleImage.slice(index + 1, uploadMultipleImage.length),
    ]);
  };

  //ends here

  /*-----------@Handler function for handle change in inputs--------------*/
  const handleChange = (e) => {
  console.log("eeee",e.target.value)
    const { name, value } = e.target;
    let newErrors = { ...error }; // Create a copy of errors
    setInput((previousValue) => ({
      ...previousValue,
      [name]: value,
    }));
    if (name === "addressLine1") {
      setGeoAddressData((previousValue) => ({
        ...previousValue,
        addressLine1: value,
      }));
    }
    if (newErrors[name]) {
      delete newErrors[name];
      setErrors(newErrors);
    }

  };

  const handleChange1 =  (value,name) =>{
    let newErrors = { ...error };
   setInput({
    ...input,[name]:value
   })
   if (newErrors[name]) {
    delete newErrors[name];
    setErrors(newErrors);
  }
  }


  const valueof = (value,name) =>{
    let newErrors = { ...error };
 
   setInput({
    ...input,[name]:value
   })
   if (newErrors[name]) {
    delete newErrors[name];
    setErrors(newErrors);
  }
  }

  /*-----------@Handler function to set profile image--------------*/
  const [file, setFile] = useState();



  const onFileChange = (e) => {
    let fileType = e.target.files[0].type.split("/")
    if (fileType[1] !== "jpeg" && fileType[1] !== "jpg" && fileType[1] !== "png") {
      return toast.error(
        "Please Upload The image with extension jpg , jpeg and png!",
        {
          theme: "dark",
        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );

    }
    setFile(URL.createObjectURL(e.target.files[0]));

    setInput((previous) => ({

      ...previous,

      image: e.target.files[0],
    }));

  };


  /*-----------@Initial useEffect hook to set data with pub details--------------*/
  

  /*-----------@Api call and set pub data--------------*/
  const _getUserDetails = () => {
    getbyUserid(
      { _id: user_id },
      (response) => {
        setUserData(response?.data?.result[0]);
        setUserData1(response?.data?.result[0]?.multipleImages);
        setInput({
          _id: response?.data?.result[0]?._id,
          full_name: response?.data?.result[0]?.full_name,
          email: response?.data?.result[0]?.email,
          contact_number: response?.data?.result[0]?.contact_number,
          restaurant_email: response?.data?.result[0]?.restaurant_email,
          countryCode:response?.data?.result[0]?.countryCode,
          restaurantContact_number:
            response?.data?.result[0]?.restaurantContact_number,
          image: null,
          addressLine1: response?.data?.result[0]?.addressLine1,
          addressLine2: response?.data?.result[0]?.addressLine2,
          city: response?.data?.result[0]?.city,
          postcode: response?.data?.result[0]?.postcode,
          requestCode: response?.data?.result[0]?.requestCode,
          is_updated: 1,
        });
        setGeoLocation({
          lat: response?.data?.result[0]?.lat,
          lng: response?.data?.result[0]?.lng,
        });
      },
      dispatch
    );
  };



  let path = `${API_BASEURL}/public/posts/${userData?.image}`;

  /*-----------@Handler function for submitting data--------------*/


  const handleAddPost = async (e) => {
    handeblur();
    e.preventDefault();
    const formData = new FormData();
    formData.append("_id", input._id);
    for (let i = 0; i < uploadMultipleImage.length; i++) {
      formData.append("image", uploadMultipleImage[i])
    }
    try {
      await addUserPost(
        formData,
        (result) => {
          setShowModal(false)
          if (result.status === "Success") {
            toast.success(
              "Data added successfully",
              {
                theme: "dark",
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000
              },


            );

            setShowModal(false);
            SetUploadMultipleImage([])
            _getUserDetails()
          } else if (result.status === "Error") {
            toast.error(
              "Data not added successfully",
              {
                theme: "dark",

                position: toast.POSITION.BOTTOM_RIGHT,
              },
              { autoClose: 1000 }
            );
          } else {
            toast.error(
              result.message,
              {
                theme: "dark",

                position: toast.POSITION.BOTTOM_RIGHT,
              },
              { autoClose: 1000 }
            );

          }
        },
        dispatch
      );
    } catch (e) {
      console.log("Error in add photos==>", e.message)
    }

  }



  const handleDelete = async (e) => {
    handeblur();
    e.preventDefault();
    await deleteGallery(
      { _id: deletePostID, userId: user_id },
      (result) => {
        setDeletePhoot(false)
        if (result.status === "Success") {
          toast.success(
            "Data Deleted successfully",
            {
              theme: "dark",
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1000
            },


          );
          setDeletePhoot(false)
          _getUserDetails()
          // setTimeout(() => {
          // navigate("/resto/mainrestodashboard");
          // }, 1000);
        } else if (result.status === "Error") {
          toast.error(
            "Data not added successfully",
            {
              theme: "dark",

              position: toast.POSITION.BOTTOM_RIGHT,
            },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            result.message,
            {
              theme: "dark",

              position: toast.POSITION.BOTTOM_RIGHT,
            },
            { autoClose: 1000 }
          );

        }
      },
      dispatch
    );
  }

  const handleSubmitdata = async (e) => {
    handeblur();
    e.preventDefault();
    const newErrors = validateForm(); // Renamed errors to newErrors
    if (Object.keys(newErrors).length === 0) {
      setErrors(newErrors);
      const formData = new FormData();
      console.log("sfdfdsdfsdfs", input)
      formData.append("_id", input._id);
      formData.append("full_name", input.full_name);
      formData.append("email", input.email);
      formData.append("contact_number", input.contact_number);
      formData.append("restaurant_email", input.restaurant_email);
      formData.append(
        "restaurantContact_number",
        input.restaurantContact_number
      );
      formData.append("countryCode",input.countryCode)
      formData.append("image", input.image);
      formData.append("addressLine1", input.addressLine1);
      formData.append("addressLine2", input.addressLine2);
      formData.append("city", input.city);
      formData.append("postcode", input.postcode);
      formData.append("requestCode", input.requestCode);
      formData.append("is_updated", input.is_updated);
      formData.append("lat", geoLocation.lat);
      formData.append("lng", geoLocation.lng);
      try {
        await addrestroForm(
          formData,
          (result) => {
            setShowModal(false)
            if (result.status === "Success") {
              toast.success(
                "Data added successfully",
                {
                  theme: "dark",
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 1000
                },


              );
              setShowModal(false);
              _getUserDetails()
              // setTimeout(() => {
              // navigate("/resto/mainrestodashboard");
              // }, 1000);
            } else if (result.status === "Error") {
              toast.error(
                "Data not added successfully",
                {
                  theme: "dark",

                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );
            } else {
              toast.error(
                result.message,
                {
                  theme: "dark",

                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );

            }
          },
          dispatch
        );
      } catch (e) {
        // failed(e);
      }
    } else {
      setErrors(newErrors);
      setShowModal(false)
    }
  };


  /*-----------@Hadler function for validating form inputs--------------*/
  const validateForm = () => {
    const errors = {};
    if (!input.full_name) {
      errors.full_name = "Full name is required";
    }

    if (!input.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(input.email)) {
      errors.email = "Email is invalid";
    }
    // if (input.contact_number) {
    //   if (
    //     !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
    //       input.contact_number
    //     )
    //   ) {
    //     errors.contact_number = "Contact number is invalid";
    //   } else if (
    //     input.contact_number.length > 11 ||
    //     input.contact_number.length < 10
    //   ) {
    //     errors.contact_number = "Contact number is invalid";
    //   }
    //   if (!input.restaurant_email) {
    //     errors.restaurant_email = "Pub Email is required";
    //   } else if (!/\S+@\S+\.\S+/.test(input.restaurant_email)) {
    //     errors.restaurant_email = "Pub Email is invalid";
    //   }
    // }
    if (!input.restaurantContact_number) {
      errors.restaurantContact_number = "Pub contact number is required";
      
    } else if (
      !isValidPhoneNumber(input.restaurantContact_number)
      // input.contact_number.length < 12 ||
      // input.contact_number.length > 13
    ) {
      // console.log(" input.contact_number.length", input.contact_number.length)
      errors.restaurantContact_number = "Pub Contact number is invalid";
    }
    // if (input.restaurantContact_number) {
    //  if (
    //     // input.restaurantContact_number.length > 11 ||
    //     input.restaurantContact_number.length < 10
    //   ) {
    //     errors.restaurantContact_number = "Pub contact number is invalid";
    //   }
    // }
    
    if (input.addressLine1 === undefined || input.addressLine1 === "") {
      // errors.addressLine1 = "Address line 1 is required";
    }
    if (input.city === undefined || input.city === "") {
      errors.city = "City is required";
    }
    if (input.postcode === undefined || input.postcode === "") {
      errors.postcode = "Postcode is required";
    }
    // else if (
    //       !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
    //         input.postcode
    //       )
    //     ) {
    //       errors.postcode = "Post code is invalid";
    //     }
    if (input.requestCode === undefined || input.requestCode === "") {
      errors.requestCode = "Request Code is required";
    } else if (input.requestCode.length !== 4) {
      errors.requestCode = "Request Code should be 4 Digit";
    }
    return errors;
  };

  const handeblur = () => {
    // const errors = validateForm();
    // if (Object.keys(errors).length === 0) {
    //   setErrors(errors);
    // } else {
    //   setErrors(errors);
    // }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleKeyDown = (e) => {
    const inputElement = document.querySelector('.pac-target-input');
    if (e.key === "Enter" && inputElement && inputElement.contains(e.target)) {
      e.preventDefault();
    }
  };




  const buttonviewHandlerpage = async () => {
    navigate("/resto/mainrestodashboard");
  };
  return (
    <>
      <div className="RIAddAddress">
        <Header />
        <section className="main">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="content">
                  <h3 className="blackText Aeonik-Bold">
                    {userData?.restaurant_name}!{" "}
                    <img src="./assets/hand.png" alt="" />
                  </h3>
                  <h1 className="blackText Spoof-Black">
                    Welcome to <span className="blackText Spoof-Black">GoTipMe</span>
                  </h1>
                  <p className="blackText Aeonik-Regular">
                    Manage your venue, pubstars and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <form>
          <section className="form">
            <div className="container">
              <div className="row my-4">
                <div className="col-lg-6">
                  <div className="content">
                    <label htmlFor="uploadFile">
                      <div className="UploadBox">
                        <div className="icon">
                          <i class="fa-solid fa-upload"></i>
                        </div>
                        <div className="text">Enter Your Profile Photo</div>
                        {/* <img src={file ? file : path} alt="" width={180} /> */}
                      </div>
                      <input
                        type="file"
                        id="uploadFile"
                        className="d-none"
                        onChange={(e) => onFileChange(e)}

                      />
                    </label>
                    <div className="uploadedImage">
                      <img src={file ? file : path} alt="" />

                      {/* <i class="fa-solid fa-xmark"></i> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 text-end p-3">
                  <p className="blackText Aeonik-Regular">
                    Pub Name :
                    <span className="fw-bold">
                      {loginRole == 2
                        ? userdata?.login?.data?.result?.restaurant_name
                        : userdata?.adminrestro?.data?.restaurant_name}
                    </span>
                  </p>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-6">
                  <div className="inputField">
                    <label htmlFor="superadmin">Pub Admin Name</label>
                    <div className="inputBox">
                      <input
                        type="text"
                        name="full_name"
                        defaultValue={userData?.full_name}
                        className="form-control px-3 bg-none"
                        id="superadmin"
                        placeholder="Super Admin Name"
                        onChange={handleChange}
                        onBlur={(e) => handeblur()}
                      />
                    </div>
                    {error.full_name && (
                      <span className="text-danger">{error.full_name}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="inputField">
                    <label htmlFor="superadminEmail">Pub Email</label>
                    <div className="inputBox">
                      <input
                        type="email"
                        name="email"
                        defaultValue={userData?.email}
                        className="form-control px-3 bg-none"
                        id="superadminEmail"
                        disabled
                        placeholder="Super Admin Email Id"
                        onChange={handleChange}
                        onBlur={(e) => handeblur()}
                      />
                    </div>
                    {error.email && (
                      <span className="text-danger">{error.email}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="inputField">
                    <label htmlFor="superadminContact">
                      Pub Admin Contact No
                    </label>
                    <div className="inputBox countryCodeBox">
                      <input
                        type="text"
                        className="form-control px-3 countryCode"
                        id="superadminContact"
                        value={"+44"}
                      />
                      <input
                        type="text"
                        className="form-control px-3"
                        id="contact"
                        placeholder="Super Admin Contact No*"
                        name="contact_number"
                        defaultValue={userData?.contact_number}
                        onChange={handleChange}
                        onBlur={(e) => handeblur()}
                      />
                    </div>
                    {error.contact_number && (
                      <span className="text-danger">
                        {error.contact_number}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                <div className="inputField1 ">
                    <label htmlFor="contact">Pub Contact #</label>
                    <div className="inputBox  blackBorder">
                     <PhoneInput
                    //  fieldname="restaurantContact_number"
                          className="form-control px-3"
                          name="restaurantContact_number"
                          placeholder="Pub Contact #"
                          international
                          // maxLength={15}
                          defaultCountry="RU"
                          value={userData.restaurantContact_number}
                          onChange={(e)=>handleChange1(e,"restaurantContact_number")}
                        
                         />

                    </div>
                    {error.restaurantContact_number && (
                        <span className="text-danger">
                          {error.restaurantContact_number}
                        </span>
                      )}
                  
                  </div>



                  {/* <div className="inputField">
                    <label htmlFor="restaurantContact">Pub Contact #</label>
                    <div className="inputBox countryCodeBox">
                    <text className="countryCode1" >+</text>
                       
                      <input
                        type="text"
                        className="form-control px-3 countryCode countryCode2"
                        name="countryCode"
                        id="restaurantContact"
                        defaultValue={userData?.countryCode}
                        onChange={handleChange}
                        onBlur={(e) => handeblur()}
                        maxLength={4}
                      />
                      <input
                        type="text"
                        className="form-control px-3"
                        id="contact"
                        name="restaurantContact_number"
                        placeholder="Restaurant/Bar Contact #"
                        onChange={handleChange}
                        defaultValue={userData?.restaurantContact_number}
                        onBlur={(e) => handeblur()}
                      />
                    </div>
                    {error.restaurantContact_number && (
                      <span className="text-danger">
                        {error.restaurantContact_number}
                      </span>
                    )}
                    {error.countryCode && (
                        <span className="text-danger">
                          {error.countryCode}
                        </span>
                      )}
                  </div> */}
                </div>
                {/* <div className="col-md-6">
                  <div className="inputField">
                    <label htmlFor="RestaurantEmail">Pub Email Id</label>
                    <div className="inputBox">
                      <input
                        type="email"
                        className="form-control px-3 bg-none"
                        id="RestaurantEmail"
                        name="restaurant_email"
                        placeholder="Restaurant/Bar Email Id"
                        onChange={handleChange}
                        defaultValue={userData?.restaurant_email}
                        onBlur={(e) => handeblur()}
                      />
                    </div>
                    {error.restaurant_email && (
                      <span className="text-danger">
                        {error.restaurant_email}
                      </span>
                    )}
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="inputField">
                    {userdata && 

<LocationMap onChange={handleChange}  valuesof={(e)=>{
  valueof((e.formatted_address),"addressLine1")
}}
          defaultValue={userData?.addressLine1} name={"addressLine1"}/>
                    }
                
                    {/* <LoadScript
                      googleMapsApiKey={
                        process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                      }
                      libraries={libraries}
                      region="US"
                      
                    >
                      <Autocomplete
                        onLoad={(ref) => {(inputRef.current = ref)}}
                        // onPlacesChanged={(e) => {handlePlaceChanged(e)}}
                      
                      >
                        <>
                          <label htmlFor="address1">Address Line 1 ee</label>
                          <div className="inputBox">
                            <input
                              type="text"
                              className="form-control px-3 bg-none"
                              id="address1"
                              name="addressLine1"
                              placeholder="Address Line 1"
                              onChange={handleChange}
                              defaultValue={userData?.addressLine1}
                              onBlur={(e) => handeblur()}
                            />
                          </div>
                          {error.addressLine1 && (
                            <span className="text-danger">
                              {error.addressLine1}
                            </span>
                          )}
                        </>
                      </Autocomplete>
                    </LoadScript> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="inputField">
                    <label htmlFor="address2">Address Line 2</label>
                    <div className="inputBox">
                      <input
                        type="text"
                        className="form-control px-3 bg-none"
                        id="address2"
                        name="addressLine2"
                        placeholder="Address Line 2"
                        onChange={handleChange}
                        defaultValue={userData?.addressLine2}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="inputField">
                    <label htmlFor="TownCity">Town/City </label>
                    <div className="inputBox">
                      <input
                        type="text"
                        className="form-control px-3 bg-none"
                        id="TownCity"
                        name="city"
                        placeholder="Town/City"
                        onChange={handleChange}
                        defaultValue={userData?.city}
                        onBlur={(e) => handeblur()}
                      />
                    </div>
                    {error.city && (
                      <span className="text-danger">{error.city}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="inputField">
                    <label htmlFor="Postcode">Postcode </label>
                    <div className="inputBox">
                      <input
                        type="text"
                        className="form-control px-3 bg-none"
                        id="Postcode"
                        placeholder="Postcode"
                        name="postcode"
                        onChange={handleChange}
                        defaultValue={userData?.postcode}
                        onBlur={(e) => handeblur()}
                      />
                    </div>
                    {error.postcode && (
                      <span className="text-danger">{error.postcode}</span>
                    )}
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="inputField">
                    <label htmlFor="RequestCode">Request Code </label>
                    <div className="inputBox">
                      <input
                        type="text"
                        className="form-control px-3 bg-none"
                        id="RequestCode"
                        placeholder="Request Code"
                        name="requestCode"
                        onChange={handleChange}
                        defaultValue={userData?.requestCode}
                        onBlur={(e) => handeblur()}
                      />
                    </div>
                    {error.requestCode && (
                      <span className="text-danger">{error.requestCode}</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-12 mt-4" style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                  <div className="btnGroups">
                    <button
                      className="btn btn-white-outline btn-height"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      style={{ marginLeft: "10px" }}
                      className="btn btn-white-outline btn-height"
                      onClick={() => {
                        setModalType(true)
                        setShowModal(true)
                      }}
                    >
                      Update Profile
                    </button>
                  </div>
                </div>

                {/* <div className="col-md-6">
                                    <div className="inputField">
                                        <label htmlFor="status">Select Status</label>
                                        <select
                                            name=""
                                            id=""
                                            className="form-select"
                                            // value={status}
                                            // onChange={handleStatusChange}
                                        >
                                            <option value="true">Active</option>
                                            <option value="false">Not Active</option>
                                        </select>
                                    </div>
                                </div> */}
              </div>
            </div>
            <div className="container">
              <div className="row" id="managePhotos">
                <div className="col-lg-9 col-12" >
                  <h4 className="mt-3">Pub Photos</h4>
                  <div className="content row">


                    <div className="col-lg-2 col-5">
                      <label htmlFor="multipleImages">
                        <div className="UploadBox">
                          <div className="icon">
                            <i class="fa-solid fa-upload"></i>
                          </div>
                          <div className="text">upload pub photos</div>
                        </div>
                        {/* <label htmlFor="MultipleImage">MultipleImage </label> */}

                        <input
                          type="file"
                          name="multipleImages"
                          id="multipleImages"
                          multiple
                          className="d-none"
                          onChange={(e) => pubImgaeupload(e)}
                        // multiple
                        // defaultValue={userData?.multipleImages}
                        /></label>
                    </div>
                    {uploadMultipleImage?.map((item, index) => {
                      return (
                        <div className="col-lg-2 col-5">
                          <span
                            className="remove_img1"
                            onClick={() => removeImage(index)}
                          >
                            X
                          </span>
                          <div className="uploadedImage" >

                            <img src={URL.createObjectURL(item)} alt="" height={150} style={{ width: "180px" }} />

                          </div>
                        </div>)

                    })}




                  </div>


                </div>
                <div className="col-lg-3 " style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                  <div className="buttonsRow">
                    <div className="navBtn"></div>
                    <div className="btnGroups">
                      <button
                        className="btn btn-white-outline btn-height"
                      // onClick={(e) => buttonviewHandlerpage()}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-white-outline btn-height"
                        onClick={() => {
                          setModalType(false)
                          setShowModal(true)
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ gap: "20px" }}>
                <h4 className="mt-3">Uploaded Pub Photos</h4>
                {userData?.photoGallery && userData?.photoGallery.map((dta, i) => (
                  <div className="col-lg-2 col-6 content" key={i} >
                    <span
                      className="remove_img"
                      onClick={() => {
                        setDeletePhoot(true);
                        setDeletePostID(dta._id)

                      }}
                    >
                      X
                    </span>
                    <div className="" >
                      <img src={`${API_BASEURL}/public/posts/${dta?.image}`} alt="" height={150} style={{ width: "180px", borderRadius: "20px", objectFit: "cover" }} />
                      {/* <i class="fa-solid fa-xmark"></i> */}
                    </div>
                  </div>))}
              </div>
            </div>

            <Modal show={deletePhoto} onHide={() => setDeletePhoot(false)}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {/* <div className="modal-heading"> */}


                  {/* </div> */}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <div className="otpBox">
                  <h5> Do you really want to delete this photo? </h5>
                </div>
                <div className="payment-btn">
                  <button
                    variant="secondary"
                    className="btn btn-white-outline btn-height"
                    onClick={() => setShowModal(false)}
                  >
                    No
                  </button>
                  <button
                    variant="primary"
                    className="btn btn-main btn-height"
                    onClick={handleDelete}
                  >
                    Yes
                  </button>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {/* <div className="modal-heading"> */}
                  {modalType ? <> <h3>Do You Really Want to Update The Profile</h3></> : <> <h3>Add photo?</h3></>}

                  {/* </div> */}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <div className="otpBox">
              <h5>Proceed </h5>
            </div> */}
                <div className="payment-btn">
                  <button
                    variant="secondary"
                    className="btn btn-white-outline btn-height"
                    onClick={() => setShowModal(false)}
                  >
                    No
                  </button>
                  <button
                    variant="primary"
                    className="btn btn-main btn-height"
                    onClick={modalType ? handleSubmitdata : handleAddPost}
                  >
                    Yes
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </section>
        </form>
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default RestoDashboard;
